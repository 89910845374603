import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

import fonts from "../../styles/fonts"


const HomeProjectsSlideContainer = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    position: relative;
    background: linear-gradient(to bottom right,${({backgroundtop}) => backgroundtop}, ${({backgroundbottom}) => backgroundbottom});

    margin: 0 10px;
    box-sizing: border-box;

    transition: .4s;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0);
    
    &:hover {
        box-shadow: 0px 0px 30px rgba(0,0,0,0.3);        

        .HomeProjectsSlideBlock {
            opacity: 1;
        }

        .HomeProjectsSlideImage {
            opacity: .1;
        }
    }
`

const HomeProjectsSlideImage = styled(Img)`
   height: 450px;
   max-width: 100%; 
   max-height: 450px;
   width: 100%;

   opacity: 1;
   transition: .4s;

   img {
    height: 450px!important;
    width: auto!important;
    max-width: 100%; 
    max-height: 450px;
    margin: 0 auto;
    bottom: 0;
    right: 0;
    object-fit: contain !important
   }
`

const HomeProjectsSlideBlock = styled.div`
    position: absolute;
    width: 70%;
    text-align: center;
    
    opacity: 0;
    transition: .4s;
`

const HomeProjectsSlideTitle = styled.h3`
    font-size: 33px;
    font-family: ${fonts.poppins};
    font-weight: bold;
    line-height: 37px;
    margin: 0 0 10px;
`

const HomeProjectsSlideDescription = styled.p`
    font-size: 16px;
    font-family: ${fonts.poppins};
    font-weight: normal;
    line-height: 29px;
    margin: 0;
`


const HomeProjectsSlide = ({data, path}) => (
    <HomeProjectsSlideContainer to={path + data.node.slug} backgroundtop={data.node.acf.project_header.project_header_gradient_colors.project_header_gradient_colors_top} backgroundbottom={data.node.acf.project_header.project_header_gradient_colors.project_header_gradient_colors_bottom}>

        <HomeProjectsSlideImage className="HomeProjectsSlideImage" fluid={data.node.acf.project_header.project_header_image.localFile.childImageSharp.fluid} />

        <HomeProjectsSlideBlock className="HomeProjectsSlideBlock">
            <HomeProjectsSlideTitle>{data.node.acf.project_header.project_header_title}</HomeProjectsSlideTitle>
            <HomeProjectsSlideDescription>{data.node.acf.project_header.project_header_subtitle}</HomeProjectsSlideDescription>
        </HomeProjectsSlideBlock>

    </HomeProjectsSlideContainer>
)

export default HomeProjectsSlide