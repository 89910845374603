import React, { Fragment } from 'react'
import styled from 'styled-components'
import Link from "gatsby-link"
import NetlifyForm from 'react-netlify-form'

import colors from '../../styles/colors'
import fonts from '../../styles/fonts'
import device from '../../styles/device'


const ContactFormS = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.21);
  width: 100%;
  position: relative;
  padding: 70px 40px 40px 40px;
  box-sizing: border-box;

  @media ${device.desktop}{
    width: 100%;
  }

  &::before{
    content: '...';
    color: #FFFFFF;
    font-size: 60px;
    line-height: 0;
    letter-spacing: 1;
    padding-left: 5px;
    box-sizing: border-box;
    background: linear-gradient(-225deg, rgb(34, 20, 254) 0%, rgb(252, 76, 93) 100%);
    border-radius: 10px 10px 0 0;
    height: 30px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  input{
    background: rgb(255, 255, 255);
    border: 1px solid rgb(232, 232, 232);
    width: 100%;
    padding: 10px;
    margin-bottom: 20px ;
    color: ${colors.tertiary};
    font-family: ${fonts.poppins};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
    
    box-sizing: border-box;
  }

  textarea{
    background: rgb(255, 255, 255);
    border: 1px solid rgb(232, 232, 232);
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    min-height: 140px;
    color: ${colors.tertiary};
    font-family: ${fonts.poppins};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;

    box-sizing: border-box;
  }

  input::placeholder, textarea::placeholder{
    color: ${colors.tertiaryGrey};
    font-family: ${fonts.poppins};
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
  }

  input:hover, textarea:hover {
    border: 1px solid ${colors.primary};
  }

  input:focus, textarea:focus {
    outline: none !important;
    border: 1px solimport { ReCAPTCHA } from 'react-google-recaptcha';
    id ${colors.primaryDark};
  }

  .checkboxContainer {
    display: flex-inline;
    color: ${colors.tertiaryLight};
    font-family: ${fonts.poppins};
    font-size: 14px;
    line-height: 20px;
    margin-right: auto;
    margin-bottom: 20px;

    a {
      color: ${colors.primary};
      
      :hover {
        color: ${colors.primaryDark};
      }
      :active {
        color: ${colors.primaryLight};
      }
    }
  }

  input[type="checkbox"] {
    display: flex-inline;
    width: inherit;
    margin: 0;
  }

  .hidden {
    display: none;
  }
`

const BtnSubmit = styled.button`
  background-color: ${colors.primary};
  border: 1px solid ${colors.primary};
  padding: 10px 60px;
  margin: 20px auto 0 0;
  font-family: ${fonts.poppins};
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  transition: all .4s;

  &:hover {
    background-color: ${colors.primaryDark};
    cursor: pointer;
  }
`

const Status = styled.span`
  margin: 15px 0;
  font-family: ${fonts.poppins};
  font-size: 14px;
  font-weight: bold;
  color: ${({color}) => color};
  margin-right: auto;
`

function ContactForm ({contact: {conditions, policyLink, policyPath, button, loadingMsg, errorMsg, successMsg, placeholders: {name, email, message, accept}}, sideMenu}){

  return(
    <ContactFormS>
      
      <NetlifyForm name='Contact from' honeypotName='bot-field'>
        {({ loading, error, success }) => (
          <Fragment>

            <input name={name} aria-label={name} placeholder={name} type="text" required/>
            <input name={email} aria-label={email} placeholder={email} type="email" required/>
            <textarea name={message} aria-label={message} placeholder={message} required/>
            <div className="checkboxContainer">
              <input name={accept} type="checkbox" aria-label={accept} required/>&nbsp;{conditions}&nbsp;<Link to={policyPath}>{policyLink}</Link>
            </div>
            <BtnSubmit aria-label="Enviar" type="submit">{button}</BtnSubmit>
                

            {loading &&
              <Status color="#16a2ba">{loadingMsg}</Status>
            }
            {error &&
              <Status color="#f54b5e">{errorMsg}</Status>
            }
            {success &&
              <Status color="#39cb4f">{successMsg}</Status>
            }
            
          </Fragment>
        )}
      </NetlifyForm>
    
    </ContactFormS>
  )
}

export default ContactForm