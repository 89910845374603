import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import {Expo, TimelineMax} from 'gsap'

import fonts from '../../styles/fonts'

const Shadow = styled.div`position: absolute;
  bottom: -50px;
  border-radius: 50%;
  background-color: black;
  opacity: 0.15;
  width: ${({animationSize}) => animationSize.desktopWidth}px;
  height: 15px;
  scale: 1;
`

const Device = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  width: ${({animationSize}) => animationSize.desktopWidth}px;
  height: ${({animationSize}) => animationSize.desktopHeight}px;
  z-index:10;
  padding:30px;
  position: absolute;
`

const Texto = styled.h1`
  color: white;
  font-family: ${fonts.poppins};
  font-size: 52px;
  font-weight: bold;
  letter-spacing: -0.71px;
  line-height: 62px;
`

const Line = styled.hr`
  color: white;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Dot1 = styled.span`
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  border-radius: 100%;
  background-color: #FFFFFF;
  top: 10px;
  left: 10px;
  opacity: 1;
`

const Dot2 = styled.span`
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  border-radius: 100%;
  background-color: #FFFFFF;
  top: 10px;
  left: 25px;
  opacity: 1;
`

const Dot3 = styled.span`
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  border-radius: 100%;
  background-color: #FFFFFF;
  top: 10px;
  left: 40px;
  opacity: 1;
`

const Btn = styled.div`
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  border-radius: 100%;
  background-color: #FFFFFF;
  bottom: 0;
  left: 0;
  opacity: 0;
`

const mobileSizes = {
  desktopWidth: 320,
  desktopHeight: 240,
  desktopFontY: 15,
  tabletWidth: 280,
  tabletHeight: 360,
  phoneWidth: 190,
  phoneHeight: 300,
  desktopFontScale: 0.6,
  tabletFontScale: 0.5,
  phoneFontScale: 0.4,
  dotsX: 10,
  dotsY: 10,
  lineYDesktop: 20,
  lineYTablet: 280,
  lineYPhone: 230,
  btnX: 85,
  btnY: -20
}



const DeviceMorph = ({heroText}) => {

  let shadowRef = useRef(null);
  let deviceRef = useRef(null);
  let textRef = useRef(null);
  let lineRef = useRef(null);
  let dot1Ref = useRef(null);
  let dot2Ref = useRef(null);
  let dot3Ref = useRef(null);
  let btnRef = useRef(null);

  useEffect( () => {
    
  const tl = new TimelineMax({repeat: -1});
  tl.set(deviceRef.current, {width: `${mobileSizes.desktopWidth}px`, height: `${mobileSizes.desktopHeight}px`, scaleY: 1})
  .set(shadowRef.current, {scaleX: 0.9})
  .set(lineRef.current, {opacity: 1, y: mobileSizes.lineYDesktop})
  .set(dot1Ref.current, {opacity: 1})
  .set(dot2Ref.current, {opacity: 1})
  .set(dot3Ref.current, {opacity: 1})
  .set(btnRef.current, {opacity: 0, x: mobileSizes.btnX, y: mobileSizes.btnY})
  .set(textRef.current, {opacity: 1, y: mobileSizes.desktopFontY, scale: mobileSizes.desktopFontScale})

  .add("intro")
  .to(deviceRef.current, 0.3, {scaleY: 1, ease: Expo.easeInOut, delay: 0.3})
  .to(shadowRef.current, 1, {scaleX: 0.9, ease: Expo.easeInOut}, "intro")
  .to(lineRef.current, 0.3, {opacity: 1, scaleX: 1, ease: Expo.easeInOut}, "intro+=0.3")
  .to(textRef.current, 0.3, {opacity: 1, y: mobileSizes.desktopFontY, ease: Expo.easeInOut}, "intro+=0.3")
  .to(dot1Ref.current, 0.2, {opacity: 1, ease: Expo.easeInOut}, "-=0.3")
  .to(dot2Ref.current, 0.2, {opacity: 1, ease: Expo.easeInOut}, "-=0.2")
  .to(dot3Ref.current, 0.2, {opacity: 1, ease: Expo.easeInOut}, "-=0.1")
  .to(dot3Ref.current, 0.2, {opacity: 1, ease: Expo.easeInOut}, "+=2") //pausa 2seg
  .to(dot1Ref.current, 0.2, {opacity: 0, ease: Expo.easeInOut}, "-=0.3")
  .to(dot2Ref.current, 0.2, {opacity: 0, ease: Expo.easeInOut}, "-=0.2")
  .to(dot3Ref.current, 0.2, {opacity: 0, ease: Expo.easeInOut}, "-=0.1")

  .add("tablet")
  .to(deviceRef.current, 1, {width: `${mobileSizes.tabletWidth}px`, height: `${mobileSizes.tabletHeight}px`, ease: Expo.easeInOut})
  .to(shadowRef.current, 1, {scaleX: 0.8, bottom: "-80px", ease: Expo.easeInOut}, "tablet")
  .to(textRef.current, 1, {y: -20, scale: mobileSizes.tabletFontScale, ease: Expo.easeInOut}, "tablet")
  .to(lineRef.current, 1, {y: mobileSizes.lineYTablet, ease: Expo.easeInOut}, "tablet")
  .to(dot3Ref.current, 0.3, {opacity: 0, ease: Expo.easeInOut}, "+=2") //pausa 2seg

  .add("phone")
  .to(deviceRef.current, 1, {width: `${mobileSizes.phoneWidth}px`, height: `${mobileSizes.phoneHeight}px`, ease: Expo.easeInOut})
  .to(shadowRef.current, 1, {scaleX: 0.6, bottom: "-90px", ease: Expo.easeInOut}, "phone")
  .to(lineRef.current, 1, {y: mobileSizes.lineYPhone, ease: Expo.easeInOut}, "phone")
  .to(textRef.current, 1, {scale: mobileSizes.phoneFontScale, y: -20, ease: Expo.easeInOut}, "phone")
  .to(btnRef.current, 0.5, {opacity: 1, ease: Expo.easeInOut}, "phone+=0.5")
  .to(btnRef.current, 0.3, {opacity: 0, ease: Expo.easeInOut}, "+=2") //pausa 2seg

  .add("desktop")
  .to(deviceRef.current, 1, {width: `${mobileSizes.desktopWidth}px`, height: `${mobileSizes.desktopHeight}px`,ease: Expo.easeInOut})
  .to(shadowRef.current, 1, {scaleX: 0.9, bottom: "-50px", ease: Expo.easeInOut}, "desktop")
  .to(lineRef.current, 1, {y: mobileSizes.lineYDesktop, ease: Expo.easeInOut}, "desktop")
  .to(textRef.current, 1, {scale: mobileSizes.desktopFontScale, y: mobileSizes.desktopFontY, ease: Expo.easeInOut}, "desktop")
  .to(dot1Ref.current, 0.2, {opacity: 1, x: 0, ease: Expo.easeInOut}, "desktop+=0.5")
  .to(dot2Ref.current, 0.2, {opacity: 1, x: 0, ease: Expo.easeInOut}, "desktop+=0.4")
  .to(dot3Ref.current, 0.2, {opacity: 1, x: 0, ease: Expo.easeInOut}, "desktop+=0.3")
  .to(dot3Ref.current, 0.2, {opacity: 1, ease: Expo.easeInOut}, "+=2") //pausa 2seg

  }, [] )

  return (
    <Device ref={ deviceRef } animationSize={mobileSizes}>
      <Shadow ref={ shadowRef } animationSize={mobileSizes}/>
      <Texto ref={ textRef }>{heroText}</Texto>
      <Line ref={ lineRef }/>
      <Dot1 ref={ dot1Ref }/>
      <Dot2 ref={ dot2Ref }/>
      <Dot3 ref={ dot3Ref }/>
      <Btn ref={ btnRef }/>
    </Device>
  );
};

export default DeviceMorph;