import React, {useState} from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useTrail, useSpring, animated } from 'react-spring'

import useIntersect from '../../utils/useIntersect'

import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device from "../../styles/device"



const ClientsSection = styled.section`
  background-color: ${({ negative }) => negative === true ? colors.primary : colors.secondary};
`

const ClientsContainer = styled.div`
  max-width: 1440px;
  
  margin: auto;
  padding: 100px 8.3333333%;

  @media ${device.tablet}{
    padding: 140px 30px;
  }

  @media ${device.phone}{
      padding: 100px 20px;
  }
`

const ClientsRow = styled.div`    
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
`

const ClientsContent = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  padding: 0 10px;

  @media ${device.tablet} {
    grid-template-columns: auto auto auto;
  }

  @media ${device.phone} {
    grid-template-columns: 50% 50%;
  }
`
const ClientImg = styled.img`
  width: 100%;
  max-width: 200px;
  user-select: none;
  user-drag: none; 

  @media ${device.tablet} {
    max-width: 140px;
  }

  @media ${device.phone} {
    max-width: 120px;
  }
`

const ClientImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;

  @media ${device.tablet} {
    &:last-child{
      display: none;
    }
  }

  @media ${device.phone} {
    &:last-child{
      display: flex;
    }
  }
`

const ClientsTitle = styled.h2`
  color: #ffffff;
  font-family: ${fonts.poppins};
  font-size: 40px;
  line-height: 45px;
  font-weight: bold;
  letter-spacing: -0.72px;
  margin-top: 0;
  margin-bottom: 120px;

  padding: 0 10px;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 60px;
  }
`

const ClientsLink = styled(Link)`
  color: rgb(255, 255, 255);
  font-family: ${fonts.poppins};
  font-size: 16px;
  text-decoration: underline;
  margin-top: 120px;
  margin-right: auto;

  padding: 0 10px;
  opacity: 1;
  transition: .2s;

  &:hover {
    opacity: .5;
  }

  @media ${device.tablet}{
    margin-top: 60px;
  }
`

const Clients = ({ data, path, negative, numberOfPosts, isHome, clients: {title, link} }) => {
  
  const [show, setShow] = useState(false)

  const thrs = 0.3
  const [ref, entry] = useIntersect({
    threshold: thrs
  });

  const titleProps = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: show ? 1 : 0,
    }
  })

  const AnimatedClientsTitle = animated(ClientsTitle)

  const trail = useTrail(data.edges.length, {
    opacity: show ? 1 : 0,
    transform: `scale(${show ? 1 : 1.4})`,
    from: {
      opacity: 0, 
      transform: `scale(1.4)`
    },
    onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
  })

  return (
    <ClientsSection negative={negative}>
      <ClientsContainer>
        <ClientsRow>
          <AnimatedClientsTitle style={titleProps}>{title}</AnimatedClientsTitle>
          <ClientsContent ref={ref} isHome={isHome}>
            {trail.map((props, index) => {
              if(!numberOfPosts || (numberOfPosts && numberOfPosts > index)) {
                return (
                  <ClientImgContainer key={data.edges[index].node.id}>
                    <animated.div style={props}>
                      <ClientImg alt={data.edges[index].node.title} src={data.edges[index].node.featured_media.localFile.childImageSharp.fixed.src} />
                    </animated.div>
                  </ClientImgContainer>
                )
              } 
              return null;
            })}
          </ClientsContent>
        {isHome && <ClientsLink to={path}>{link}</ClientsLink>}
        </ClientsRow>
      </ClientsContainer>
    </ClientsSection>
  )
}

export default Clients
