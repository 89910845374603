export default {

  sideMenu: {
    primary: [
      {
        name: "Projects",
        slug: "/en/projects/"
      },
      {
        name: "Services",
        slug: "/en/services/"
      },
      {
        name: "Binarios",
        slug: "/en/binarios/"
      },
      {
        name: "Contact",
        slug: "/en/contact/"
      }/*,
      {
        name: "Blog",
        slug: "/en/blog/"
      }*/
    ],
    home: "/en/",
    languages: ["Español", "Català", "English"]
  },

  home: {
    helmet: {
      title: "Web development and design | Binaria, digital agency",
      meta: "Binaria is a digital agency from Barcelona with 20 years experience. We do UI/UX design, websites, progressive web apps and more digital products."
    },
    heroText: "Digital product connecting technics & creativity",
    intro: "We're a digital agency based in Barcelona. We combine our passion for design focused in people with advanced development technologies.",
    projects: {
      title: "Our work",
      description: "Discover some of our projects",
      link: "All projects"
    },
    servicios: {
      title: "What we do",
      link: "Services"
    },
    clients: {
      title: "Our clients",
      link: "About us"
    },
    contactHome: {
      title: "Send us a message",
      description: "We're thrilled to hear what you want to tell us and offer the solutions you need for your business"
    }
  },

  projects: {
    helmet: {
      title: "Projects | Binaria, digital agency",
      meta: "We show you some of the projects on which we've collaborated with our clients. Find examples of the digital initiatives we work with."
    },
    title: "Our work",
    description: "Discover our projects, digital experiencies of easy use, agile and reliable",
    navProjects: {
      left: "Previous",
      center: "All projects",
      right: "Next"
    }
  },

  services: {
    helmet: {
      title: "Services | Binaria, digital agency",
      meta: "Discover all Binaria's services. We're specialized in Web Development and UX/UI design, among others."
    },
    heroTitle: "Leave us your ship's key, we'll pilot it",
    servicesListTitle: "What we do",
    sliderTitle: "How we do it",
    carousel: {
      title: "Why are we passionte about it?",
      subTitle: "Because we believe that a good product...",
      sentences: {
        allSentencesWithHighlights: [
          "...is <span class='primary'>open</span> and <span class='secondary'>collaborative</span>",
          "...requires <span class='primary'>time</span> and <span class='secondary'>constancy</span>",
          "...is developed every day <span class='primary'>learning</span> and <span class='secondary'>researching</span>",
          "...comes from <span class='primary'>restless</span> and <span class='secondary'>humble</span> minds",
          "...is <span class='primary'>innovative</span> and <span class='secondary'>charismatic</span>",
          "...satisfies the user's <span class='primary'>needs</span> and <span class='secondary'>expectations</span>",
          "...definitely, is the <span class='primary'>sinergy</span> between two <span class='secondary'>qualities</span>",
        ],
        allSentences: [
          "...is open and collaborative",
          "...requires time and constancy",
          "...is developed every day learning and researching",
          "...comes from restless and humble minds",
          "...is innovative and charismatic",
          "...satisfies the user's needs and expectations",
          "...definitely, is the sinergy between two qualities",
        ]
      }
    }
  },

  binarios: {
    helmet: {
      title: "About us | Binaria, digital agency",
      meta: "We'll tell you how and why we do our passion. We are the Binaria team."
    },
    heroTitle: "We're two forces that create a solution",
    whys: {
      title: "Why Binaria",
      description: "We're a team devoted to each project from beggining to end. We combine creativity and technique to make digital experiences with a quality design and an optimal and efficient development. Specialized in usability, information architecture and advanced programming, we use agile methodologies to ensure the quality o our projects and team."
    },
    teamTitle: "Team",
    clients: {
      title: "Our clients"
    },
  },

  contact: {
    helmet: {
      title: "Contact us | Binaria, digital agency",
      meta: "Contact Binaria, we're thrilled to know you and be able to collaborate with you! Fill the form and we'll contact you shortly."
    },
    title: "Shoot ",
    description: "We're a digital agency specialized in product design and advanced development. We're thrilled to hear what you want to tell us and offer you the solutions for your business.",
    phone: "Phone",
    email: "Email",
    address: "Address",
    address1: "Av Josep Tarradellas, 123, 6th floor",
    address2: "08029 Barcelona",
    address3: "Aticco",
    viewMap: "See map",
    placeholders: {
      name: "Name and last name",
      email: "Email",
      message: "Message",
      accept: "acceptance"
    },
    conditions: "I accept to receive emails from Binaria and their",
    policyLink: "privacy policy",
    policyPath: "/en/privacy-policy",
    button: "Send",
    loadingMsg: "Sending...",
    errorMsg: "Your message coudn't be sent. Please, try it again.",
    successMsg: "We received your message, thank you!!"
  },

  blog: {
    helmet: {
      title: "Blog | Binaria, digital agency",
      meta: "Learn about our latest updates in the digital world. From the Binaria team, we'll tell you what you need to know to be up to date with the trends."
    },
    title: "Blog ",
    subTitle: "Discover our reflections about UI/UX design and development."
  },

  post: {
    otherPostsTitle: "Others articles that you might find interesting:"
  },

  contactBanner: {
    normal: {
      title: "Tell us about your project",
      button: "Contact",
      link: "/en/contact"
    },
    postBanner: {
      title: "Do you want to know more?",
      button: "Contact",
      link: "/en/contact"
    }
  },

  footer: {
    links: [
      {
        name: "Projects",
        slug: "/en/projects/"
      },
      {
        name: "Services",
        slug: "/en/services/"
      },
      {
        name: "Binarios",
        slug: "/en/binarios/"
      },
      {
        name: "Contact",
        slug: "/en/contact/"
      },
      /*{
        name: "Blog",
        slug: "/en/blog/"
      },*/
      {
        name: "Jobs",
        slug: "/en/jobs/"
      },
    ],
    legal: [
      "© 2019 Binaria Web Services S.L. All rights reserved. Legal notice and privacy policy. ",
      {
        name: "Legal notice",
        path: "/en/legal-notice/"
      },
      " y ",
      {
        name: "privacy policy",
        path: "/en/privacy-policy/"
      }
    ]
  },

  cookies: {
    description: "This website uses cookies to improve your experience. Navigating in it, we understand you agree with our ",
    link: "privacy policy",
    acceptButton: "Accept",
    rejectButton: "Reject",
    path: "/en/privacy-policy/"
  },

  errorPage: {
    metaTitle: "Error 404 | Binaria, digital agency",
    metaDesc: "We tell you how and why we do what we're passionate about. We are the Binaria team.",
    title: "Error 404",
    text: "Sorry! the page you are looking for does not exist!",
    button: "Go back",
    link: "/en/"
  }

}