import React, {useRef, useState} from "react"
import styled from "styled-components"
import Slider from 'react-slick'
import { useSpring, animated } from 'react-spring'
import { Link } from 'gatsby'

import useIntersect from '../../utils/useIntersect'

import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device, {deviceSize} from "../../styles/device"

import blueArrow from "../../images/blue-arrow.svg"
import blueArrowDark from "../../images/blue-arrow-dark.svg"

import HomeProjectsSlide from "./homeProjectsSlide"


const ProjectsHomeContainer = styled.section`
    font-family: ${fonts.poppins};
    max-width: 1440px;
    
    margin: auto;
    padding: 100px 8.3333333%;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;

    @media ${device.tablet}{
      padding: 140px 30px;
    }

    @media ${device.phone}{
        padding: 100px 20px;
    }
`

const ProjectsHomeRow = styled.div`    
    margin: 0 -10px;
`

const ProjectsHomeTitle = styled.h2`
  color: ${colors.tertiaryDark};
  font-size: 40px;
  line-height: 45px;
  
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;

  padding: 0 10px;

  @media ${device.phone} {
    font-size: 40px;
    line-height: 45px;
  }
`

const ProjectsHomeDescription = styled.p`
  color: ${colors.tertiaryDark};
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  margin: 15px 0 60px;

  padding: 0 10px;
`

const ProjectsHomeGallery = styled.div`
  margin: 0 auto;

  .slick-list {
    overflow: visible;
  }

  .slick-arrow {
    display: none !important;
  }
`

const ProjectsHomeGalleryNav = styled.div`
    display: flex;
    text-align: center;

    margin-top: 40px;
    padding: 0 10px;

    div {
        text-indent: -9999em;
        text-transform: uppercase;
        user-select: none;
        cursor: pointer;
        border: red;
        width: 40px;
        height: 40px;
        background: url('${blueArrow}') no-repeat center center;
        transition: .2s;
        
        :hover {
          background: url('${blueArrowDark}') no-repeat center center;
        }
        
        :active, :focus {
            outline: none;
        }
    }

    .button_left {
        transform: rotate(90deg);
        margin-right: 20px;
    }

    .button_right {
        transform: rotate(-90deg);
    }

    .desactive {
      background: url('${blueArrow}') no-repeat center center;
      opacity: .5;
    }
`

const ProjectsHomeGalleryLink = styled(Link)`
  font-family: ${fonts.poppins};
  font-size: 16px;
  line-height: 24px;
  color: ${colors.primary};
  margin: 0 0 0 auto;
`

const ProjectsHome = ({ data, path, projects: {title, description, link} }) => {

  const SliderRef = useRef(null)

  const next = () => {
      SliderRef.current.slickNext()
    }
  const previous = () => {
      SliderRef.current.slickPrev()
    }
  const handleChangeSlide = (i) => {
    i === 0 ? document.querySelector('.button_left').classList.add('desactive') : document.querySelector('.button_left').classList.remove('desactive')

    window.innerWidth >= deviceSize.tablet ? (
      i === data.edges.length - SliderRef.current.props.slidesToShow ? document.querySelector('.button_right').classList.add('desactive') : document.querySelector('.button_right').classList.remove('desactive')
    ) : (
      i === data.edges.length - SliderRef.current.props.responsive[0].settings.slidesToShow ? document.querySelector('.button_right').classList.add('desactive') : document.querySelector('.button_right').classList.remove('desactive')
    )
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    draggable: false,
    afterChange: (current) => handleChangeSlide(current),
    responsive: [
        {
          breakpoint: deviceSize.tablet,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 300,
          }
        }
    ]
  };

  const thrs = 0.1

  const [ref, entry] = useIntersect({
    threshold: thrs
  });

  const [show, setShow] = useState(false)

  const props = useSpring({
    from: {
      opacity: 0, 
      transform: `translateX(200px)`
    },
    to: {
      opacity: show ? 1 : 0,
      transform: `translateX(${show ? 0 : 200}px)`
    },
    onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
  })

  const AnimatedProjectsHomeRow = animated(ProjectsHomeRow);


  return (
    <ProjectsHomeContainer ref={ref} id="projects">
      <AnimatedProjectsHomeRow style={props}>

          <ProjectsHomeTitle>{title}</ProjectsHomeTitle>

          <ProjectsHomeDescription>{description}</ProjectsHomeDescription>

          <ProjectsHomeGallery>

            <Slider ref={SliderRef} {...settings}>
              { data.edges.map((item, index) => <HomeProjectsSlide data={item} path={path} key={`homeProjectsSlide${index}`}/>) }
            </Slider>

            <ProjectsHomeGalleryNav sliderFamily="design">
              <div className="button_left desactive" onClick={() => previous()} />
              <div className="button_right" onClick={() => next()} />

              <ProjectsHomeGalleryLink to={path}>{link}</ProjectsHomeGalleryLink>

            </ProjectsHomeGalleryNav>

          </ProjectsHomeGallery>

      </AnimatedProjectsHomeRow>
    </ProjectsHomeContainer>
  )
}

export default ProjectsHome
