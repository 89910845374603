import React, {useState} from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import fonts from '../styles/fonts'
import device from '../styles/device'
import { useSpring, animated } from 'react-spring'
import useIntersect from '../utils/useIntersect'


const HomeIntroContainer = styled.section`
    max-width: 1440px;
    
    margin: auto;
    padding: 160px 16.66666% 100px 16.66666%;

    @media ${device.tablet}{
        padding: 200px 30px 60px;
    }

    @media ${device.phone}{
        padding: 140px 20px 40px;
    }
`

const HomeIntroRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    
    margin: 0 -10px;
`


const HomeIntroText = styled.div`
  font-family: ${fonts.poppins};
  color: ${colors.introText};
  font-size: 28px;
  font-weight: bold;
  line-height: 45px;
  text-align: center;

  padding: 0 10px;

  @media ${device.phone}{
    text-align: left;
  }
`


const HomeIntro = ({intro}) => {

  //useIntersect devulve ref y entry. ref es la referencia del elemento del cual queremos controlar su visualización en el viewport
  //entry es el objeto con la información de la posición del elemento
  const [ref, entry] = useIntersect({
    //threshold es la cantidad de elemento visible para que se dispare el evento
    threshold: 0.5
  });

  
  const [show, setShow] = useState(false)
  //props de transformacion de react-spring para la animación del elemento
  // en el parametro "to" hacemos uso de un condicional para saber si el elemento está en el viewport y por tanto tenenmos que animarlo

  const props = useSpring({
    from: {
      opacity: 0, 
      transform: `translateY(200px)`
    },
    to: {
      opacity: show ? 1 : 0,
      transform: `translateY(${show ? 0 : 200}px)`
    },
    onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
  })

  //Encapsulamos el componente que queremos animar dentro de un componente devuelto por la función animated de react-spring
  const AnimatedHomeIntroText = animated(HomeIntroText)

  return (
    <HomeIntroContainer id="home_intro">
      <HomeIntroRow ref={ref}>
        <AnimatedHomeIntroText dangerouslySetInnerHTML={{__html: intro}} style={props} />
      </HomeIntroRow>
    </HomeIntroContainer>
  )
}

export default HomeIntro;