import React, {useState} from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import device from "../../styles/device"
import ContactForm from "./contactForm"
import { useSpring, animated } from 'react-spring'
import useIntersect from '../../utils/useIntersect'



const ContactContainer = styled.section`
  max-width: 1440px;
  
  margin: auto;
  padding: 100px 8.3333333%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;

  @media ${device.tablet}{
    padding: 140px 30px;
  }

  @media ${device.phone}{
      padding: 100px 20px;
  }
`

const ContactRow = styled.div`    
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const ContactHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  width: 41.6666%;
  padding: 0 10px;
  box-sizing: border-box;

  @media ${device.tablet} {
    width: 100%;
    margin-bottom: 80px;
    justify-content: flex-start;
    align-items:  flex-start;
  }
`
const ContactContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 50%;
  padding: 0 10px;
  box-sizing: border-box;

  margin-left: auto;

  @media ${device.tablet} {
    width: 100%;
  }
`
const ContactTitle = styled.h2`
  color: ${colors.tertiary};
  font-family: ${fonts.poppins};
  font-size: 40px;
  line-height: 45px;
  font-weight: bold;
  letter-spacing: -0.72px;
  margin: 0;
  z-index: 5;

  @media ${device.tablet} {
  }
`

const ContactDescription = styled.p`
  font-family: ${fonts.poppins};
  color: ${colors.tertiary};
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  margin: 40px 0 0;

  @media ${device.tablet} {
    margin-top: 40px;
  }
`

const Contact = ({contact, contactHome, sideMenu}) => {

  const thrs = 0.1
  const [ref, entry] = useIntersect({
    threshold: thrs
  });
  
  const [show, setShow] = useState(false)

  const TitleProps = useSpring({
    from: {
      opacity: 0, 
      transform: `translateX(-200px)`
    },
    to: {
      opacity: show ? 1 : 0,
      transform: `translateX(${show ? 0 : -200}px)`
    }
  })

  const FormProps = useSpring({
    from: {
      opacity: 0, 
      transform: `translateX(200px)`,
      width: '100%',
    },
    to: {
      opacity: show ? 1 : 0,
      transform: `translateX(${show ? 0 : 200}px)`
    },
    onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
  })

  const AnimatedContactHeader = animated(ContactHeader);

  return (
    <ContactContainer>
      <ContactRow ref={ref}>

        <AnimatedContactHeader style={TitleProps}>
          <ContactTitle>{contactHome.title}</ContactTitle>
          <ContactDescription>{contactHome.description}</ContactDescription>
        </AnimatedContactHeader>

        <ContactContent>
          <animated.div style={FormProps}>
            <ContactForm contact={contact} sideMenu={sideMenu}/>
          </animated.div>
        </ContactContent>

      </ContactRow>
    </ContactContainer>
  )
}

export default Contact
