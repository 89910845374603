import React from 'react'
import styled from 'styled-components'
import device from '../../styles/device'

const CtaScrollDownS = styled.div`    
    position: absolute;
    padding: 24px;
    bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 62px;
    overflow: hidden;

    opacity: .5;
    transition: .2s;

    animation: flip-flop .8s infinite;

    

    transition: all .4s ease;

    :hover {
        opacity: .4
    }

    @media ${device.phone}{
        display: none;
    }

    &:hover {
        cursor: pointer;
        opacity: 1;
    }

    * {
        background-color: #FFFFFF;
    }

    .center {
        height: 18px;
        width: 2px;
        margin-top: auto;
    }
    
    .left {
        height: 12px;
        width: 2px;

        position: absolute;
        bottom: 24px;

        transform: rotate(45deg);
        transform-origin: bottom left;
    }

    .right {
        height: 12px;
        width: 2px;

        position: absolute;
        bottom: 24px;

        transform: rotate(-45deg);
        transform-origin: bottom right;
    }

    @keyframes flip-flop {
        0%   { transform: translate(0, 0) }
        50% { transform: translate(0, 10px) }
    }
`

const CtaScrollDown = ({target}) => (
  <CtaScrollDownS onClick={() => {document.querySelector(target).scrollIntoView({behavior: 'smooth'})}}>
    <div className="center"></div>
    <div className="left"></div>
    <div className="right"></div>
  </CtaScrollDownS>

)

export default CtaScrollDown