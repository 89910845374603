import React, {useState} from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useSpring, animated } from 'react-spring'

import fonts from '../../styles/fonts'

import useIntersect from '../../utils/useIntersect'

const Service = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 100px 0 0;
  flex: 0 0 420px;
  user-select: none;
  user-drag: none;
  cursor: pointer;

  img {
    user-drag: none; 
    user-select: none;
  }
`

const ServiceTitle = styled.h3`
  color: #FFFFFF;
  font-family: ${fonts.poppins};
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.53px;
  line-height: 32px;
  text-align: left;
  margin-bottom: 0;
`
const ServiceContent = styled.div`
  color: #FFFFFF;
  font-family: ${fonts.poppins};
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 29px;
  text-align: left;
`

const ServiceImageS = styled.div`
  width: 100%;
  align-self: center;

  .gatsby-image-wrapper {
    > div {
      padding-bottom: 100px !important;
    }

    img {
        max-height: 100px;
        width: auto !important;
        top: 50% !important;
        left: 0;
        transform: translate( 0, -50%);
    }
  }
`

const ServicesTouchContainer = styled.div`
  background-color: #4836ff;
  width: 100%;
  margin: 0 auto;
  min-width: 0;
  min-height:0;
  padding: 0 10px;
  box-sizing: border-box;

  .slick-list{
    overflow: visible;
    margin-bottom: 30px;
  }

  .slick-dots{
    text-align: left;
    display: flex !important;
    bottom: -45px;
  }

  .slick-dots li:first-child{
    margin-left:0
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: #FFFFFF;
  }
`

const ServicesTouchCarousel = ({data}) => {

  const ServiceComp = ({itemData}) => {
    return(
      <Service>
        <ServiceImageS><Img alt={ itemData.title } fluid={ itemData.featured_media.localFile.childImageSharp.fluid } /></ServiceImageS>
        <ServiceTitle>{ itemData.title }</ServiceTitle>
        <ServiceContent dangerouslySetInnerHTML={{ __html: itemData.content }} />
      </Service>
    )
  }

  const thrs = 0.1
  const [ref, entry] = useIntersect({
    threshold: thrs
  });

  const [show, setShow] = useState(false)

  const props = useSpring({
    from: {
      opacity: 0, 
      transform: `translateX(200px)`
    },
    to: {
      opacity: show ? 1 : 0,
      transform: `translateX(${show ? 0 : 200}px)`
    },
    onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
  })

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  
  return(
    <ServicesTouchContainer  ref={ref}>
      <animated.div style={props}>
        <Slider {...settings}>
          { data.edges.map((item, index) => <ServiceComp index={index} itemData={item.node} key={`srv${index}`}/>) }
        </Slider>
      </animated.div>
    </ServicesTouchContainer>
  )
}

export default ServicesTouchCarousel