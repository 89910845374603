import React, {useState} from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import useIntersect from '../../utils/useIntersect'

import colors from '../../styles/colors'
import fonts from '../../styles/fonts'
import device from '../../styles/device'

import ServicesTouchCarousel from './servicesTouchCarousel'



const ServicesSection = styled.section`
  background-color: ${colors.primary};
`

const ServicesContainer = styled.div`
  max-width: 1440px;
  
  margin: auto;
  padding: 100px 8.3333333%;

  @media ${device.tablet}{
    padding: 140px 30px;
  }

  @media ${device.phone}{
      padding: 100px 20px;
  }
`

const ServicesRow = styled.div`    
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
`

const ServicesTitle = styled.h2`
  color: #ffffff;
  font-family: ${fonts.poppins};
  font-size: 40px;
  line-height: 45px;
  font-weight: bold;
  letter-spacing: -0.72px;
  margin-top: 0;
  margin-bottom: 120px;

  padding: 0 10px;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 60px;
  }
`

const ServicesLink = styled(Link)`
  color: rgb(255, 255, 255);
  font-family: ${fonts.poppins};
  font-size: 16px;
  text-decoration: underline;
  margin-top: 120px;
  margin-right: auto;

  padding: 0 10px;
  opacity: 1;
  transition: .2s;

  &:hover {
    opacity: .5;
  }

  @media ${device.tablet}{
    margin-top: 60px;
  }
`

const Services = ({ data, path, isHome, servicios: {title, link} }) => {

  const thrs = 0.1
  const [ref, entry] = useIntersect({
    threshold: thrs
  });
  
  const [show, setShow] = useState(false)

  const AnimatedServicesTitle = animated(ServicesTitle)

  const props = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: show ? 1 : 0,
    },
    onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
  })


  return (
    <ServicesSection>
      
      <ServicesContainer ref={ref} isHome={isHome}>
        <ServicesRow>

          <AnimatedServicesTitle style={props}>{title}</AnimatedServicesTitle>

          <ServicesTouchCarousel data={data}/>

          <ServicesLink to={path}>{link}</ServicesLink>

        </ServicesRow>
      </ServicesContainer>

    </ServicesSection>
  )
}

export default Services
