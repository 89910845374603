import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import device, {deviceSize} from '../../styles/device'
import colors from '../../styles/colors'

import DeviceMorphDesktop from './deviceMorphDesktop'
import DeviceMorphMobile from './deviceMorphMobile'
import CtaScrollDown from '../ctaScrollDown'

const HomeHeroS = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0 200px 0;
  height: 100vh;
  position: relative;

  &, * {
    box-sizing: border-box;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(270deg, ${colors.primary}, ${colors.secondary});
    background-size: 200% 100%;
    animation: AnimationName 10s ease infinite;

    z-index: 0;
  }

  @keyframes AnimationName { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
`
const HomeHeroContainer = styled.div`
  display: flex;
  margin: 100px auto 0;
  padding-left: 8.3333%;
  padding-right: 8.3333%;
  justify-content: center;
  align-items: center;
  height: 100%;

  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;

  @media ${device.tablet}{
    flex-direction: column;
  }
`

const HomeHero = ({heroText}) => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect( () => {
    const setWidth = () => setWindowWidth(window.innerWidth);

    if(typeof window !== 'undefined') {
      setWidth();
      window.addEventListener("resize", () => setWidth)
    }

    return () => window.removeEventListener("resize", () => setWidth)

  }, []);

  
  //spring animations:
  const props = useSpring({
    from: {
        opacity: 0,
        transform: 'scale(1.2)'
    },
    to: {
        opacity: 1,
        transform: 'scale(1)'
    }
  });

  const AnimatedHomeHeroContainer = animated(HomeHeroContainer);

  return (
    <HomeHeroS>
      <AnimatedHomeHeroContainer style={props}>

        {windowWidth < deviceSize.tablet ? <DeviceMorphMobile heroText={heroText}/> : <DeviceMorphDesktop heroText={heroText}/>}

      </AnimatedHomeHeroContainer>

      <CtaScrollDown target="#home_intro"/>

    </HomeHeroS>
  )
}

export default HomeHero;